import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import codeSplitLoad from 'Webapp/shared/utils/code-split-load';
import styled from '@emotion/styled';

// Utils
import { getSectionId } from 'Utils/content/flipboard-urls';
import { VIEWPORT_TYPES } from 'Utils/viewport-types';
import { GA } from 'Utils/analytics';
import { USAGE_NAV_FROMS } from 'Utils/analytics/usage';
import getWindow from 'Utils/get-window';
import ArticleRenderingUtil from 'Webapp/shared/utils/article-rendering-util';
import { ArticleContentSectionType, RelatedSectionType } from 'Webapp/enums';
import { LAYOUT_SIZES } from 'Style/layout';

// Components
import { THEMES as ImageThemes } from '../components/base/image';
import Item from '../components/item';
import PageClasses from '../components/page-classes';
import Loading from '../components/loading';
import { NavFromContext } from 'ComponentLibrary/context';
import VisibilityScrollToBottomTracker from '../components/visibility-scroll-to-bottom-tracker';
import NotePreview from 'Webapp/shared/app/views/article/standalone-post-header';

// Redesigned article preview components
import CTABannerRedesigned from '../components/cta-banner-redesigned';
import ItemDetails from 'Webapp/shared/app/views/article/item-details';
import MoreStories from './article/more-stories';
import ContextSection from '../components/context-section';
import withT from 'ComponentLibrary/hocs/withT';
import withBreadcrumbs from '../hocs/withBreadcrumbs';
import withHistory from 'Webapp/shared/app/hocs/withHistory';

import connector from 'Utils/connector';
import connectTheme from 'Webapp/shared/app/connectors/connectTheme';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';
import connectAppUrl from 'Webapp/shared/app/connectors/connectAppUrl';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectRouting from 'Webapp/shared/app/connectors/connectRouting';
import connectContextualOnboarding from 'Webapp/shared/app/connectors/connectContextualOnboarding';
import connectFlipMode from 'Webapp/shared/app/connectors/connectFlipMode';
import connectFeatureFlags from 'Webapp/shared/app/connectors/connectFeatureFlags';

const FirstPartyVideo = codeSplitLoad('FirstPartyVideo');
const ThirdPartyVideo = codeSplitLoad('ThirdPartyVideo');

const StyledArticle = styled.div((props) => ({
  marginTop: props.isFlipModeEnabled
    ? `-${LAYOUT_SIZES.MAIN_NAV_PLUS_FLIP_MODE_HEIGHT}`
    : null,
}));
const StyledFirstPartyVideo = StyledArticle.withComponent(FirstPartyVideo);
const StyledThirdPartyVideo = StyledArticle.withComponent(ThirdPartyVideo);

class Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: null,
    };

    this.trackPageview = this.trackPageview.bind(this);
    this.setBreadcrumbs = this.setBreadcrumbs.bind(this);
  }

  componentDidMount() {
    const { article, routing, setAppUrl } = this.props;

    if (article) {
      const navFrom = article.isFirstPartyVideo
        ? USAGE_NAV_FROMS.VIDEO
        : USAGE_NAV_FROMS.ARTICLE;
      this.props.setAdjustNavFrom(navFrom);
    }

    // Reset Adjust URL in nav bar
    this.props.setNavAppStoreUrl();

    this.fetchArticle();

    if (article && article.id) {
      setAppUrl(article.appUrl);
      this.trackPageview(article, routing.pathname);
      this.props.loadPreviousAndSaveCurrentVisitedItems(article);
    }

    // Gets the window height in order to set max-height on the image for tall images
    // Subtract for main header + medium spacing + spacing to reveal the first line of the title
    //  - Keeping this math spelled out so it's clear what it accounts for.

    if (getWindow()) {
      this.setState({ windowHeight: getWindow().innerHeight - (85 + 12 + 60) });
    }

    this.setBreadcrumbs();
  }

  componentDidUpdate(prevProps) {
    if (this.props.articleRemoteId !== prevProps.articleRemoteId) {
      this.fetchArticle();
    }

    const {
      article,
      routing,
      navFromOverride,
      setNavFromOverride,
      setAppUrl,
      loadPreviousAndSaveCurrentVisitedItems,
    } = this.props;

    const id = article && article.id;
    const previousArticleId = prevProps.article && prevProps.article.id;
    const articleChanged = id && id !== previousArticleId;

    if (previousArticleId && articleChanged && navFromOverride) {
      setNavFromOverride(null);
    }

    if (articleChanged) {
      setAppUrl(article.appUrl);

      loadPreviousAndSaveCurrentVisitedItems(article);

      this.trackPageview(article, routing.pathname);

      this.setBreadcrumbs();

      if (article) {
        const navFrom = article.isFirstPartyVideo
          ? USAGE_NAV_FROMS.VIDEO
          : USAGE_NAV_FROMS.ARTICLE;
        this.props.setAdjustNavFrom(navFrom);
      }
    }
  }

  componentWillUnmount() {
    this.props.setNavFromOverride(null);
    this.props.setAdjustNavFrom(undefined);
  }

  setBreadcrumbs() {
    const { article, articleSection, setBreadcrumbs } = this.props;
    setBreadcrumbs({
      item: article,
      section: articleSection,
    });
  }

  trackPageview(article, pathname) {
    const { history, usageTrackItemEnter, usageSetMobileGateArticle } =
      this.props;
    usageTrackItemEnter(article, history);
    usageSetMobileGateArticle(article);
    GA.trackPageview(this.props.setTitle({ article }), pathname);
  }

  fetchArticle() {
    const {
      article,
      articleRemoteId,
      routing: { params },
      userid,
    } = this.props;

    if (article) {
      return;
    }

    const sectionRemoteId = getSectionId(params);
    if (articleRemoteId) {
      this.props.getArticle(articleRemoteId, userid, sectionRemoteId);
    }
  }

  render() {
    const {
      article,
      articleSection,
      relatedSections,
      isAmp,
      showAmpStory,
      loading,
      usageTrackReachedItemEnd,
      isDesktop,
      isSmallScreen,
      t,
      isFlipModeEnabled,
      featureFlags,
    } = this.props;

    const isFirstPartyVideo = article && article.isFirstPartyVideo;
    const isThirdPartyVideo =
      article && !article.isFirstPartyVideo && article.isVideo;

    // When rendering full-screen (currently only used for AMP stories),
    // omit most page DOM structure.
    if (showAmpStory) {
      return (
        <Item
          item={article}
          truncateTitleLength={400}
          truncateExcerptLength={400}
          showExcerpt
          showReadMore
          shownInList={false}
          useCrop={false}
          windowHeight={this.state.windowHeight}
          relatedArticlesSection={this.props.relatedSections.filter(
            (section) => section.type === RelatedSectionType.STORYBOARDS,
          )}
          imageTheme={ImageThemes.large}
        />
      );
    }

    if (loading || !article) {
      return <Loading isView />;
    }
    const { isStandalonePost } = article;

    if (isFirstPartyVideo) {
      return (
        <StyledFirstPartyVideo
          item={article}
          articleSection={articleSection}
          relatedSections={relatedSections}
          isFlipModeEnabled={isFlipModeEnabled}
        />
      );
    }

    if (isThirdPartyVideo) {
      return (
        <StyledThirdPartyVideo
          item={article}
          articleSection={articleSection}
          relatedSections={relatedSections}
          isFlipModeEnabled={isFlipModeEnabled}
        />
      );
    }

    const notSameItemFilter = (item) => item.title !== article.title;

    const classes = classNames({
      'article-redesigned': true,
      amp: isAmp,
    });

    const showSideBar = isDesktop;

    const contentsClassName = classNames('article__details-contents', {
      'article__details-contents__no-aside': !showSideBar,
    });

    const contentSections =
      ArticleRenderingUtil.determineContentDisplaySections(
        relatedSections,
        isDesktop,
        t,
      );

    const renderContextSection = (
      { section, headerOverride, hideSeeMore, maxItems },
      condensedItems = false,
    ) => {
      const truncateItemTitleLength = isDesktop || isSmallScreen ? 40 : 180;
      return (
        <ContextSection
          section={section}
          sectionItemLimit={maxItems}
          headerOverride={headerOverride}
          hideSeeMore={hideSeeMore}
          condensedItems={condensedItems}
          notSameItemFilter={notSameItemFilter}
          truncateItemTitleLength={
            condensedItems ? truncateItemTitleLength : null
          }
        />
      );
    };
    return (
      <NavFromContext.Provider value={USAGE_NAV_FROMS.ARTICLE}>
        <StyledArticle
          className={classes}
          isFlipModeEnabled={isFlipModeEnabled}
        >
          <PageClasses enableFullWidth>
            {featureFlags.STANDALONE_POST && isStandalonePost ? (
              <NotePreview
                item={article}
                contentSections={contentSections}
                shownInList={false}
              />
            ) : (
              <div className="article__details">
                <div className={contentsClassName}>
                  {article && (
                    <ItemDetails
                      item={article}
                      section={articleSection}
                      shownInList={false}
                    />
                  )}
                  {isDesktop &&
                    contentSections[ArticleContentSectionType.SIDEBAR] && (
                      <aside className="article__aside">
                        <MoreStories
                          article={article}
                          articleSection={
                            contentSections[ArticleContentSectionType.SIDEBAR]
                              .section
                          }
                          limit={4}
                          headerOverride={
                            contentSections[ArticleContentSectionType.SIDEBAR]
                              .headerOverride
                          }
                          className="see-more--related"
                        />
                      </aside>
                    )}
                </div>
              </div>
            )}
            <div className="centered-column--large">
              <div className="article__related-sections">
                {contentSections[ArticleContentSectionType.CONDENSED_0] &&
                  contentSections[ArticleContentSectionType.CONDENSED_0]
                    .section &&
                  renderContextSection(
                    contentSections[ArticleContentSectionType.CONDENSED_0],
                    true,
                  )}
                {contentSections[ArticleContentSectionType.CONDENSED_1] &&
                  contentSections[ArticleContentSectionType.CONDENSED_1]
                    .section &&
                  renderContextSection(
                    contentSections[ArticleContentSectionType.CONDENSED_1],
                    true,
                  )}
                {contentSections[ArticleContentSectionType.ITEM_CARD_1] &&
                  contentSections[ArticleContentSectionType.ITEM_CARD_1]
                    .section &&
                  renderContextSection(
                    contentSections[ArticleContentSectionType.ITEM_CARD_1],
                  )}
              </div>
            </div>
            <CTABannerRedesigned />
            {contentSections[ArticleContentSectionType.ITEM_CARD_2] &&
              contentSections[ArticleContentSectionType.ITEM_CARD_2]
                .section && (
                <div className="centered-column--large">
                  <div className="article__related-sections">
                    {renderContextSection(
                      contentSections[ArticleContentSectionType.ITEM_CARD_2],
                    )}
                  </div>
                </div>
              )}
            <VisibilityScrollToBottomTracker
              onScrolledToBottom={() => usageTrackReachedItemEnd(article)}
            />
          </PageClasses>
        </StyledArticle>
      </NavFromContext.Provider>
    );
  }
}

Article.propTypes = {
  routing: PropTypes.object.isRequired,
  article: PropTypes.object,
  articleRemoteId: PropTypes.string,
  articleSection: PropTypes.object,
  userid: PropTypes.number.isRequired,
  getArticle: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  showAmpStory: PropTypes.bool.isRequired,
  isAmp: PropTypes.bool,
  viewportType: PropTypes.oneOf(Object.values(VIEWPORT_TYPES)),
  setAppUrl: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  setNavAppStoreUrl: PropTypes.func.isRequired,
  setNavFromOverride: PropTypes.func.isRequired,
  navFromOverride: PropTypes.string,
  setAdjustNavFrom: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  setBreadcrumbs: PropTypes.func,
  e: PropTypes.array,
  loadPreviousAndSaveCurrentVisitedItems: PropTypes.func.isRequired,
  usageTrackItemEnter: PropTypes.func.isRequired,
  usageTrackReachedItemEnd: PropTypes.func.isRequired,
  usageSetMobileGateArticle: PropTypes.func.isRequired,
  isPhone: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
  getPriorityOrderedRelatedSections: PropTypes.func,
  relatedSections: PropTypes.array,
  isFlipModeEnabled: PropTypes.bool.isRequired,
  featureFlags: PropTypes.object.isRequired,
};

Article.defaultProps = {
  article: null,
  articleRemoteId: null,
  articleSection: null,
  isAmp: false,
  loading: true,
  navFromOverride: null,
};

export default connector(
  connectContextualOnboarding,
  connectRouting,
  connectTheme,
  connectAmp,
  connectResponsive,
  connectAppUrl,
  connectAuthentication,
  connectFlipMode,
  connectFeatureFlags,
)(withHistory(withT(withBreadcrumbs(Article))));
