import {
  setPageCanonicalPath,
  setNotFound,
  setSocialGraphTitle,
  setTitle,
  setMetaDescription,
  setSocialGraphDescription,
} from '../actions/app';
import sentry from 'Utils/sentry';
import {
  isSupportedType,
  isFirstPartyVideo,
  isVideo,
  isNote,
} from 'Utils/content/item-util';
import FlapUtil from 'Utils/content/flap-util';
import { PromiseAll } from 'Utils/promise';
import {
  getSectionPath,
  getCanonicalArticlePath,
} from 'Utils/content/flipboard-urls';
import SectionUtil from 'Utils/content/section-util';
import { set } from 'Utils/redux';
import updateFeed from 'Utils/api/flap/endpoints/update-feed';

import { ARTICLES_TYPES } from 'Webapp/action-types';
import { getSection } from './section-actions';

import {
  loadRelatedArticlesSection,
  getRelatedStoryboards,
  getAuthorSection,
  getRelatedTopics,
  geRelatedFlipboardTVSection,
} from './item-actions';

function updateAppCanonicalPath(article) {
  const canonicalPath = getCanonicalArticlePath(article);
  return setPageCanonicalPath(canonicalPath);
}

/**
 * Handles response when status is not 200
 * @param  {Object} error - response error
 * @param  {Object} data - response data
 */
function handleGetArticleFailed(error) {
  sentry.captureException(error);
  sentry.addBreadcrumb('handleGetArticleFailed');
}

/**
 * Handles response when no items were returned
 * @param  {Object} data - response data
 */
function handleNoItems() {
  sentry.addBreadcrumb('handleNoItems');
}

/**
 * Request for Article
 * @param  {String} remoteId - Article remoteid
 * @param  {String} uid      - uid of logged-in user
 * @param  {Number} [limit=30] - Number of items to fetch for
 * @return {Promise}
 */
function requestArticle(remoteid, uid, limit = 30) {
  return async function (_dispatch, _getState, { flap }) {
    try {
      const params = {
        sections: remoteid,
        limit,
      };
      const { data } = await updateFeed(flap, uid, params);
      if (data.stream) {
        const metadata = data.stream.find(
          (d) => d.EOS && d.type === 'metadata',
        );
        if (metadata && metadata.noItemStatus && metadata.noItemsText) {
          handleNoItems();
          return null;
        }
      }
      let items = [];
      items = getItemsFromStream(data);
      if (items.length === 0) {
        handleNoItems();
        return null;
      }
      return items;
    } catch (error) {
      handleGetArticleFailed(error);
      return null;
    }
  };
}

/**
 * Gets items from article response
 * @param  {Object} data - Request response data
 * @return {Array}       - Array of items
 */
function getItemsFromStream(data) {
  if (!data || !data.stream) {
    return [];
  }
  return data.stream.filter((item) => isSupportedType(item));
}

/**
 * Handles response of the get Article request
 * @param  {String} remoteId - Article remoteid
 * @param  {String} sectionId - Article section remoteid (used for
 * routes that refer to an article in the context of a section)
 *                              Example: /article/:sourceURL */
function getArticle(remoteId, uid, sectionId = null) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: ARTICLES_TYPES.GET_ARTICLE });

      const getArticleSection = async () => {
        if (sectionId !== null) {
          await dispatch(
            getSection(sectionId, {
              limit: 8,
              preferMagazineContext: true,
            }),
          );
        }
      };

      const getArticle = async () => {
        const items = await dispatch(requestArticle(remoteId, uid));
        if (!items || !items[0]) {
          return null;
        }
        const [article] = items;
        return article;
      };

      const [article] = await PromiseAll([getArticle(), getArticleSection()]);

      const {
        sections: { entries },
      } = getState();
      const section = FlapUtil.getSectionByRemoteId(sectionId, entries);

      if (!article) {
        let redirectPath = null;
        if (section) {
          const projectedSection = SectionUtil.projection(section);
          redirectPath = getSectionPath(projectedSection);
        }
        if (redirectPath) {
          dispatch({
            type: ARTICLES_TYPES.GET_ARTICLE_FAILED,
            payload: { redirectPath },
          });
        } else {
          // No section to fall back on, Render 404
          dispatch(setNotFound(true));
        }
        return null;
      }

      dispatch(updateAppCanonicalPath(article));
      dispatch({
        type: ARTICLES_TYPES.GET_ARTICLE_SUCCEEDED,
        payload: {
          article,
          remoteId,
          articleSectionId: sectionId,
        },
      });

      dispatch(setSocialGraphTitle({ articleRemoteId: remoteId }));
      dispatch(setTitle({ articleRemoteId: remoteId }));
      dispatch(setMetaDescription({ articleRemoteId: remoteId }));
      dispatch(setSocialGraphDescription({ articleRemoteId: remoteId }));

      const relatedSectionPromises = [];

      if (!isNote(article)) {
        relatedSectionPromises.push(
          dispatch(loadRelatedArticlesSection(remoteId, article)),
        );
        relatedSectionPromises.push(
          dispatch(getAuthorSection(remoteId, article)),
        );
      }
      if (isFirstPartyVideo(article)) {
        relatedSectionPromises.push(
          ...dispatch(getRelatedTopics(remoteId, article)),
        );
      } else if (isVideo(article)) {
        relatedSectionPromises.push(
          ...dispatch(getRelatedTopics(remoteId, article)),
        );
        relatedSectionPromises.push(
          dispatch(geRelatedFlipboardTVSection(remoteId, article)),
        );
      } else {
        if (!isNote(article)) {
          relatedSectionPromises.push(
            dispatch(getRelatedStoryboards(remoteId, article)),
          );
          relatedSectionPromises.push(
            ...dispatch(getRelatedTopics(remoteId, article)),
          );
        }
        // TODO: Load Recommended Magazines https://flipboard.atlassian.net/browse/FL-19397
      }

      await PromiseAll(relatedSectionPromises);
    } catch (e) {
      sentry.captureException(e);
    }
  };
}

const setVideoArticleIdHack = (articleId) =>
  set(
    ARTICLES_TYPES.SET_VIDEO_ARTICLE_ID_HACK,
    'videoArticleRemoteIdHack',
    articleId,
  );

export default {
  getArticle,
  setVideoArticleIdHack,
};
