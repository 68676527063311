import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from '@emotion/styled';

// Utils
import Attribution from 'Utils/content/attribution';

// Components
import ItemCardMedia from '../../components/item/card/media';
import { THEMES as ImageThemes } from '../../components/base/image';
import Header from '../../components/base/header';
import FlipTargetMagazine from '../../components/item/flip-target-magazine';
import TopicTags from '../../components/item/topic-tags';
import SourceAttribution from '../../components/attribution/source';
import ItemCardExcerpt from '../../components/item/card/excerpt';
import ReadMoreOnSource from '../../components/item/read-more-on-source';
import ItemSocialActions, {
  StyledMetrics,
} from '../../components/item/item-social-actions';
import { SPACING } from 'Style/spacing';
import ItemStructuredData from '../../structured-data/item';
import { ImageLoadingMode } from 'Webapp/enums';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';

const ItemDetailsMedia = styled.div({
  backgroundColor: 'var(--color--surface-primary)',
});

const StyledItemSocialActions = styled(ItemSocialActions)({
  [StyledMetrics]: {
    paddingLeft: SPACING.MEDIUM,
  },
});

function ItemDetails({ item, section, t }) {
  const magazine = Attribution.getMagazine(item);
  const className = classNames({
    'item-details': true,
  });
  const responsiveLayoutClassName = classNames(
    'item-details__responsive-layout',
    {
      'item-details__responsive-layout--media-only':
        !section && !item.isArticle,
    },
  );
  const imageTheme = ImageThemes.large;

  return (
    <div className={className}>
      <ItemStructuredData item={item} section={section} t={t} />
      <div className={responsiveLayoutClassName}>
        <ItemDetailsMedia>
          <div className="item-details__image-wrapper">
            <ItemCardMedia
              item={item}
              section={section}
              shownInList={false}
              imageTheme={imageTheme}
              link={false}
              loadingMode={ImageLoadingMode.eager}
            />
          </div>
          <StyledItemSocialActions item={item} section={section} />
        </ItemDetailsMedia>
        {!item.isFlipComposeImage && (
          <div className="item-details__content">
            <div className="item-details__metadata">
              {item.sanitizedTitle && (
                <Header
                  primary
                  className="article-text--title--large item-details__title"
                >
                  {item.sanitizedTitle}
                </Header>
              )}
              <ItemCardExcerpt item={item} section={section} />
              <SourceAttribution
                className="item-details__source ui-body--large-standard"
                item={item}
                shownInList={false}
              />
              <ReadMoreOnSource item={item} section={section} />
              <TopicTags topics={item.topics} />
            </div>
          </div>
        )}
      </div>
      {magazine && <FlipTargetMagazine item={item} magazine={magazine} />}
    </div>
  );
}

ItemDetails.propTypes = {
  item: PropTypes.object.isRequired,
  section: PropTypes.object,
  t: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool.isRequired,
};

ItemDetails.defaultProps = {
  section: null,
};

export default connector(connectResponsive)(withT(ItemDetails));
