import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setAdjustNavFrom,
  setNavFromOverride,
  setIsChromeless,
  setNavAppStoreUrl,
  setTitle,
  setAppUrl,
} from '../redux/actions/app';
import ArticleActions from '../redux/actions/articles-actions';
import {
  usageTrackItemEnter,
  usageTrackReachedItemEnd,
  usageSetMobileGateArticle,
} from 'Webapp/shared/app/redux/actions/usage-actions';
import Article from '../views/article';
import FlapUtil from 'Utils/content/flap-util';
import {
  getCurrentArticleRemoteId,
  getCurrentArticle,
} from '../redux/selectors/article';
import ArticleRenderingUtil from 'Webapp/shared/utils/article-rendering-util';

const mapStateToProps = (state) => {
  const {
    articles,
    app,
    auth,
    sections: { entries },
  } = state;
  let currentArticle;
  let remoteId = null;
  if (app.isAmp) {
    [currentArticle] = Object.values(articles.articlesByRemoteId);
  } else {
    remoteId = getCurrentArticleRemoteId(state);
    currentArticle = getCurrentArticle(state);
  }

  const articleSection =
    currentArticle &&
    currentArticle.articleSectionId &&
    FlapUtil.getSectionByRemoteId(currentArticle.articleSectionId, entries);

  const relatedSections =
    ArticleRenderingUtil.getOrderedRelatedSectionsForArticle(
      currentArticle,
      entries,
    );
  return {
    article: currentArticle,
    articleRemoteId: remoteId,
    articleSection,
    isLikedItem: currentArticle && currentArticle.isLikedItem,
    relatedSections,
    userid: auth.uid,
    loading: articles.loading,
    title: app.title,
    showAmpStory: app.showAmpStory,
    isChromeless: app.isChromeless,
    navFromOverride: app.navFromOverride,
    e: entries,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    Object.assign(
      {},
      {
        setAdjustNavFrom,
        setNavFromOverride,
        setIsChromeless,
        setNavAppStoreUrl,
        setTitle,
        setAppUrl,
        usageTrackItemEnter,
        usageTrackReachedItemEnd,
        usageSetMobileGateArticle,
      },
      ArticleActions,
    ),
    dispatch,
  );

const ArticleContainer = connect(mapStateToProps, mapDispatchToProps)(Article);

export default ArticleContainer;
