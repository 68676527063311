import React from 'react';

// Components
import withT from 'ComponentLibrary/hocs/withT';
import WebLink from '../web-link';

interface ReadMoreOnSourcePassedProps {
  item: Flipboard.Item;
}

type ReadMoreOnSourceProps = ReadMoreOnSourcePassedProps & {
  t: Flipboard.TFunction;
};

const ReadMoreOnSource: React.FC<ReadMoreOnSourceProps> = ({ item, t }) => {
  if (!item.sourceDomain || item.isFlipComposeImage) {
    return null;
  }

  return (
    <p className="read-more-in-source">
      <WebLink href={item.openURL} className="link--underline" openInNewWindow>
        {t('read_more_on_source', { source: item.sourceDomain })}
      </WebLink>
    </p>
  );
};

export default withT(ReadMoreOnSource);
