import React from 'react';
import styled from '@emotion/styled';
import { BREAKPOINTS } from 'Style/breakpoints';
import { SURFACE_COLORS, DIVIDER_COLORS } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import { LAYOUT_SIZES } from 'Style/layout';
import { styleObject } from 'Style/type-helpers';

// Components
import MoreStories from './more-stories';
import ItemPost from 'Webapp/shared/app/components/item/item-post.js';

import withT from 'ComponentLibrary/hocs/withT';
import connector from 'Utils/connector';
import connectFlipMode, {
  ConnectFlipModeProps,
} from 'Webapp/shared/app/connectors/connectFlipMode';
import connectResponsive, {
  ConnectResponsiveProps,
} from 'Webapp/shared/app/connectors/connectResponsive';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';

import { ContentSectionsType } from 'Webapp/enums';

const StyledStandalonePostHeaderWrapper = styled.div({
  backgroundColor: SURFACE_COLORS.secondary,
  paddingTop: SPACING.BASE4X,
  paddingBottom: SPACING.BASE4X,
  display: 'grid',
});
const StyledStandalonePostHeader = styled.div(
  {
    display: 'flex',
    margin: '0 auto',
    gap: SPACING.BASE4X,
  },
  styleObject(
    BREAKPOINTS.desktopUp({
      paddingTop: SPACING.BASE8X,
      paddingBottom: SPACING.BASE8X,
    }),
  ),
);
const ItemCardWrapper = styled.div({
  border: `2px solid ${DIVIDER_COLORS.primary}`,
  borderRadius: SPACING.MEDIUM,
  overflow: 'hidden',
  maxWidth: LAYOUT_SIZES.MAIN_COLUMN_HALF_WIDTH,
});

type StandalonePostHeaderProps = {
  isFlipModeEnabled?: boolean;
  item: Flipboard.Item;
  section: Flipboard.Section;
  contentSections: Flipboard.Section[];
  t: Flipboard.TFunction;
} & ConnectFlipModeProps &
  ConnectResponsiveProps &
  ConnectModalProps;
const StandalonePostHeader: React.FC<StandalonePostHeaderProps> = ({
  item,
  section,
  contentSections,
  isDesktop,
}) => (
  <StyledStandalonePostHeaderWrapper>
    <StyledStandalonePostHeader>
      {item && (
        <ItemCardWrapper>
          <ItemPost item={item} section={section} />
        </ItemCardWrapper>
      )}
      {isDesktop && contentSections[ContentSectionsType.SIDEBAR] && (
        <aside>
          <MoreStories
            article={item}
            articleSection={
              contentSections[ContentSectionsType.SIDEBAR].section
            }
            limit={4}
            headerOverride={
              contentSections[ContentSectionsType.SIDEBAR].headerOverride
            }
            className="see-more--related"
          />
        </aside>
      )}
    </StyledStandalonePostHeader>
  </StyledStandalonePostHeaderWrapper>
);

export default connector<StandalonePostHeaderProps>(
  connectFlipMode,
  connectResponsive,
  connectModal,
)(withT(StandalonePostHeader));
